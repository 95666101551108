import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Paramger from "../private/Paramger/Paramger";
import CadEmpresa from "../private/CadEmpresa/CadEmpresa";
import CadMesa from "../private/CadMesa/CadMesa";
import CadSecao from "../private/CadSecao/CadSecao";
import Dashboard from "../private/Dashboard/Dashboard";
import Pdv from "../private/Pdv/Pdv";
import CadParceiro from "../private/CadParceiro/CadParceiro";
import CadProduto from "../private/CadProduto/CadProduto";
import PedidoCompra from "../private/PedidoCompra/PedidoCompra";
import CadTributacao from "../private/CadTributacao/CadTributacao";
import PedidoCompraDetalhes from "../private/PedidoCompra/PedidoCompraDetalhes";
import PreVenda from "../private/PreVenda/PreVenda";
import Pedidos from "../private/Pedidos/Pedidos";
import ListarPedidos from "../private/Pedidos/ListarPedidos";
import ClientePage from "../private/CadCliente/ClientePage.jsx";
import MeioPagamentoPage from "../private/CadMeioPagamento/MeioPagamentoPage";
import GerenciamentoLoja from "../private/GerenciamentoLoja/GerenciamentoLoja";
import CadProdutos from "../private/Acompanhamento/CadProdutos/CadProdutos";
import CadSecoes from "../private/Acompanhamento/CadSecoes/CadSecoes";
import CadImpressora from "../private/CadImpressora/CadImpressora";

const PrivateRoutes = () => {
  return (
    <Routes >
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/" element={<Dashboard />} />
      <Route path="/pedidos/:create?" element={<Pedidos />} />
      <Route path="/pedidos/listar/todos" element={<ListarPedidos />} />
      <Route path="/paramger" element={<Paramger />} />
      <Route path="/organizacao" element={<CadEmpresa />} />
      <Route path="/cadempresa" element={<CadEmpresa />} />
      <Route path="/cadmesa" element={<CadMesa />} />
      <Route path="/pdv" element={<Pdv />} />
      <Route path="/cadsecao" element={<CadSecao />} />
      <Route path="/cadparceiro" element={<CadParceiro />} />
      <Route path="/gerenciamento" element={<GerenciamentoLoja />} />
      <Route path="/cliente" element={<ClientePage />} />
      <Route path="/forma-pagamento" element={<MeioPagamentoPage />} />
      <Route path="/impressoras" element={<CadImpressora />} />
      <Route path="/cadproduto" element={<CadProduto />} />
      <Route path="/pedidocompra" element={<PedidoCompra />} />
      <Route path="/pedidocompradetalhes/:cod_pedido_compra" element={<PedidoCompraDetalhes />} />
      <Route path="/cadtributacao" element={<CadTributacao />} />
      <Route path="/prevenda" element={<PreVenda />} />
      <Route path="/acompanhamentos/secoes" element={<CadSecoes />} />
      <Route path="/acompanhamentos" element={<CadProdutos />} />
      <Route path="/auth" element={<Navigate to="dashboard" />} />
      <Route path="*" element={<Navigate to="dashboard" />} />
    </Routes>
  );
};

export default PrivateRoutes;
