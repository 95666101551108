/* eslint-disable eqeqeq */

import React, { useEffect, useState } from "react";
import "./SectionAdditionalModal.scss";
import { getSecoes } from "../../../../services/SecaoService";

const SectionAdditionalModal = ({ ...props }) => {
  const [sections, setSections] = useState([]);
  const [data, setData] = useState([]);
  const [hasIndexies, setHasIndexies] = useState([]);
  const [deletedIndexies, setDeletedIndexies] = useState([]);

  useEffect(() => {
    handleGetSections();
  }, []);
  useEffect(() => {
    setHasIndexies(props.ids);
  }, [props.ids]);
  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  function handleGetSections() {
    getSecoes("", 1, null, "all", "S")
      .then((res) => {
        setSections(res.rows);
      })
      .catch((err) => {
        console.error(err.response ? err.response.data : err.message);
      });
  }

  function handleDeleteById(category) {
    const newArray = [];
    hasIndexies.forEach((item, index) => {
      return item !== category
        ? newArray.push(item)
        : props.ids.includes(item)
        ? [
            setDeletedIndexies([...deletedIndexies, item]),
            props.deletedIds([...deletedIndexies, item]),
          ]
        : "";
    });
    setHasIndexies(newArray);
    props.setIds(newArray);

    const newData = [];
    data.forEach((item) => {
      return item.cod_secao !== category ? newData.push(item) : "";
    });
    setData(newData);
    props.setData(newData);
  }
  function handleRestauredIDs(category) {
    if (deletedIndexies.includes(category)) {
      var filtered = deletedIndexies.filter((item) => {
        return item != category;
      });
      setDeletedIndexies(filtered);
      props.deletedIds(filtered);
    }
  }
  // function handleSetData() {
  //   props.setData(data);
  //   props.setIds(hasIndexies);
  //   props.deletedIds(deletedIndexies);
  // }

  return (
    <main>
      <div id="additional-modal">
        <div className="title-content">
          <p className="title-modal">Selecione os adicionais</p>
        </div>
        <section>
          <div className="additional-modal-items">
            {sections?.map((item, index) => (
              <label className=" mt-2 additional-modal-item" key={index + 1}>
                <span className="category-title">
                  <img
                    width={50}
                    height={50}
                    alt=""
                    crossOrigin="anonymous"
                    src={process.env.REACT_APP_API_URL + `/${item.imagem}`}
                  />{" "}
                  <span>{item.descricao}</span>
                </span>

                <input
                  checked={hasIndexies?.includes(item.cod_secao)}
                  type="checkbox"
                  style={{ width: 20, height: 20, marginLeft: 40 }}
                  onChange={() => []}
                  onClick={() => [
                    props.setIds([...hasIndexies, item.cod_secao]),

                    setData([...data, item]),
                    hasIndexies?.includes(item.cod_secao)
                      ? handleDeleteById(item.cod_secao)
                      : setHasIndexies([...hasIndexies, item.cod_secao]),
                    handleRestauredIDs(item.cod_secao),
                  ]}
                />
              </label>
            ))}
          </div>
        </section>
        {/* <button
          onClick={() => [
            props.setData(data),
            props.setIds(hasIndexies),
            props.deletedIds(deletedIndexies),
          ]}
          className="save w-100"
          style={{ height: 45 }}
        >
          Salvar
        </button> */}
      </div>
    </main>
  );
};

export default SectionAdditionalModal;
