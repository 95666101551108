import React, { useEffect, useState } from "react";
import "./SelectAdditionalModal.scss";
import { Box, Modal } from "@mui/material";
import { getAdditionalsByIdProduct } from "../../../helpers/Api";
import { Load } from "../../../widgets/spinner/Spinner";
import { getTamanhoByCod, getTamanhos } from "../../../../services/TamanhoService";

const SelectAdditionalModal = ({ ...props }) => {
  const [additionals, setAdditionals] = useState([]);
  const [data, setData] = useState([]);
  const [hasIndexies, setHasIndexies] = useState([]);
  const [limitSection, setLimitSection] = useState([]);
  const [tamanhos, setTamanhos] = useState([]);
  const [selectedTamanho, setSelectedTamanho] = useState(null);
  const [price, setPrice] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    handleGetAdditionals();
    handleGetTamanhos();
  }, [props.id]);

  function handleGetAdditionals() {
    setLoading(true);
    getAdditionalsByIdProduct(props.id)
      .then((res) => {
        if (res.data.product) {
          const newData = res.data.product.tb_secaos?.map((item) => {
            return {
              ...item,
              quantidade: 0,
              tb_produtos: item.tb_produtos?.map((subItem) => ({
                ...subItem,
                quantidade: 0,
              })),
            };
          });
          setData(newData);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleAddAdditionals(indexSection, indexProduct, product) {
    const productPrice = data[indexSection].tb_produtos[indexProduct].preco_venda_vista;
    setPrice((prevPrice) => prevPrice + productPrice);

    setData((prevData) => {
      const newData = [...prevData];
      const section = { ...newData[indexSection] };
      const products = [...section.tb_produtos];
      const productItem = { ...products[indexProduct] };
      productItem.quantidade = (productItem.quantidade || 0) + 1;
      products[indexProduct] = productItem;
      section.tb_produtos = products;
      section.quantidade = (section.quantidade || 0) + 1;
      newData[indexSection] = section;
      return newData;
    });

    setHasIndexies((prevHasIndexies) => [...prevHasIndexies, indexProduct]);

    setAdditionals((prevAdditionals) => [
      ...prevAdditionals,
      {
        amount: (additionals.find((ad) => ad.cod_produto === product.cod_produto)?.amount || 0) + 1,
        name: product.descricao,
        price: productPrice,
        cod_produto: product.cod_produto,
        cod_secao: product.cod_fk_secao,
      },
    ]);

    if (data[indexSection].quantidade + 1 === data[indexSection].web_quantidade) {
      setLimitSection((prevLimitSection) => [...prevLimitSection, indexSection]);
    }
  }

  function handleSubAdditionals(indexSection, indexProduct, product) {
    const productPrice = data[indexSection].tb_produtos[indexProduct].preco_venda_vista;
    setPrice((prevPrice) => prevPrice - productPrice);

    setData((prevData) => {
      const newData = [...prevData];
      const section = { ...newData[indexSection] };
      const products = [...section.tb_produtos];
      const productItem = { ...products[indexProduct] };
      productItem.quantidade = (productItem.quantidade || 1) - 1;
      products[indexProduct] = productItem;
      section.tb_produtos = products;
      section.quantidade = (section.quantidade || 1) - 1;
      newData[indexSection] = section;
      return newData;
    });

    setHasIndexies((prevHasIndexies) =>
      prevHasIndexies.filter((item) => item !== indexProduct)
    );

    setAdditionals((prevAdditionals) =>
      prevAdditionals.filter((item) => item.cod_produto !== product.cod_produto)
    );

    setLimitSection((prevLimitSection) =>
      prevLimitSection.filter((item) => item !== indexSection)
    );
  }

  function handleAdditional(indexSection, indexProduct, product) {
    const productPrice = data[indexSection].tb_produtos[indexProduct].preco_venda_vista;

    setPrice((prevPrice) => {
      const existingAdditional = additionals.find(
        (ad) => ad.cod_secao === product.cod_fk_secao
      );
      if (existingAdditional) {
        return prevPrice - existingAdditional.price + productPrice;
      } else {
        return prevPrice + productPrice;
      }
    });

    setAdditionals((prevAdditionals) => {
      const filtered = prevAdditionals.filter(
        (item) => item.cod_secao !== product.cod_fk_secao
      );
      filtered.push({
        amount: 1,
        name: product.descricao,
        price: productPrice,
        cod_produto: product.cod_produto,
        cod_secao: product.cod_fk_secao,
      });
      return filtered;
    });
  }

  function handleGetTamanhos() {
    setLoading(true);
    getTamanhoByCod(props.id)
      .then((res) => {
        setTamanhos(res);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleClearFields() {
    props.setFalse();
    setAdditionals([]);
    setHasIndexies([]);
    handleGetAdditionals();
    setSelectedTamanho(null);
  }

  function handleTamanhoChange(event) {
    const tamanhoSelecionado = tamanhos.find(
      (tamanho) => tamanho.descricao === event.target.value
    );
    setSelectedTamanho(tamanhoSelecionado);
  }

  function handleSave() {
    if (!selectedTamanho) {
      alert("Por favor, selecione um tamanho.");
      return;
    }
    props.setAddiotionals(additionals, selectedTamanho);
    handleClearFields();
  }

  return (
    <Modal open={props.open} onClose={handleClearFields}>
      <Box>
        <main className="select-additional-modal">
          <section className="first-section">
            <p className="title"></p>
            <i
              className="fa-solid fa-xmark"
              role="button"
              onClick={() => handleClearFields()}
            ></i>
          </section>
          {tamanhos?.length > 0 && (
            <section className="tamanhos-section">
              <p className="sub-title--additional">Selecione um tamanho</p>
              {tamanhos.map((tamanho, index) => (
                <div key={index} className="tamanho-item">
                  <input
                    type="radio"
                    name="tamanho"
                    id={`tamanho-${tamanho.cod_tamanho}`}
                    onChange={handleTamanhoChange}
                    value={tamanho.descricao}
                  />
                  <label htmlFor={`tamanho-${tamanho.cod_tamanho}`}>
                    {tamanho.descricao}
                  </label>
                </div>
              ))}
            </section>
          )}
          <p
            className="sub-title"
            style={{
              textAlign: "center",
              borderBottom: "2px solid #f2f2f2",
            }}
          >
            Adicionais
          </p>
          <section className="second-section">
            <div className="additionals">
              {loading ? (
                <Load />
              ) : data?.length === 0 ? (
                <p
                  style={{
                    textAlign: "center",
                    fontWeight: 500,
                    marginTop: 30,
                  }}
                >
                  Esse produto não tem adicionais
                </p>
              ) : (
                data?.map((item, index) => (
                  <div key={index}>
                    {item.acompanhamento === "S" && (
                      <section className="additional">
                        <p className="sub-title--additional">
                          {item.descricao} (Escolha{" "}
                          {item.web_quantidade > 1
                            ? `até ${item.web_quantidade} itens`
                            : "um item"}
                          )
                        </p>
                        {item.tb_produtos.map((subItem, subIndex) => (
                          <div className="additional-item" key={subIndex}>
                            <div className="item-information">
                              <span className="item-information-title">
                                {subItem.descricao}
                              </span>
                              <br />
                              <span className="item-information-price">
                                R${" "}
                                {parseFloat(subItem.preco_venda_vista).toFixed(
                                  2
                                )}
                              </span>
                            </div>
                            <div className="item-action">
                              {item.web_quantidade > 1 ? (
                                <div className="amount">
                                  <button
                                    onClick={() =>
                                      handleSubAdditionals(
                                        index,
                                        subIndex,
                                        subItem
                                      )
                                    }
                                    disabled={
                                      !subItem.quantidade ||
                                      subItem.quantidade === 0
                                    }
                                  >
                                    -
                                  </button>
                                  <span>{subItem.quantidade || 0}</span>
                                  <button
                                    onClick={() =>
                                      handleAddAdditionals(
                                        index,
                                        subIndex,
                                        subItem
                                      )
                                    }
                                    disabled={
                                      limitSection.includes(index) ||
                                      hasIndexies.includes(subIndex)
                                    }
                                  >
                                    +
                                  </button>
                                </div>
                              ) : (
                                <input
                                  type="radio"
                                  name={`additional--one-${item.cod_secao}`}
                                  onChange={() =>
                                    handleAdditional(index, subIndex, subItem)
                                  }
                                />
                              )}
                            </div>
                          </div>
                        ))}
                      </section>
                    )}
                  </div>
                ))
              )}
            </div>
          </section>
          <button className="save" onClick={handleSave}>
            Salvar
          </button>
        </main>
      </Box>
    </Modal>
  );
};

export default SelectAdditionalModal;
